import { Create, required, SimpleForm, TextInput, FileInput, FileField } from 'react-admin';
import { CreateProps } from 'ra-ui-materialui/dist/cjs/types';

export const EffectCreate = (props: CreateProps) => {
  return (
    <Create {...props}>
      <SimpleForm warnWhenUnsavedChanges>
        <TextInput source='name' validate={[required()]} />
        <FileInput source='effect'
                   label='Effect file'
                   multiple={false}
                   labelSingle='Effect accepted'
                   placeholder={<p>✨Drop effect file here...</p>}
                   validate={[required()]}>
          <FileField source='src' title='title' />
        </FileInput>
        <FileInput source='icon'
                   label='Icon file'
                   accept='image/*'
                   multiple={false}
                   labelSingle='Icon accepted'
                   placeholder={<p>📁Drop icon file here...</p>}
                   validate={[required()]}>
          <FileField source='src' title='title' />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};
