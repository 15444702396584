import { Create, required, SimpleForm, TextInput } from 'react-admin';
import { CreateProps } from 'ra-ui-materialui/dist/cjs/types';

export const TipsCreate = (props: CreateProps) => {
    return (
        <Create {...props}>
            <SimpleForm warnWhenUnsavedChanges>
                <TextInput source='text' validate={[required()]} />
            </SimpleForm>
        </Create>
    );
};
