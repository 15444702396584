import { Create, required, SimpleForm, TextInput, email } from 'react-admin';
import { CreateProps } from 'ra-ui-materialui/dist/cjs/types';

export const UserCreate = (props: CreateProps) => {
  return (
    <Create {...props}>
      <SimpleForm warnWhenUnsavedChanges>
        <TextInput source='email' validate={[required(), email()]} />
      </SimpleForm>
    </Create>
  );
};
