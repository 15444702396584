import {
    ImageField,
    TabbedShowLayout,
    Tab,
    DateField,
    FunctionField,
    ReferenceField,
    Show,
    ShowProps,
    SimpleShowLayout,
    TextField, useShowController
} from 'react-admin';

export const JokersShow = (props: ShowProps) => {

    const { record } = useShowController(props);

    function setStyle(indicator: string, value: string, max_value: string) {
        const colorsMap = new Map([
            ["craziness", "rgba(255, 0, 0, 0.7)"],
            ["stamina", "rgba(170, 255, 0, 0.7)"],
            ["elegance", "rgba(0, 89, 255, 0.7)"],
            ["extra_craziness", "rgba(255, 0, 187, 0.7)"]
        ])
        const width = +value / +max_value * 100 + "%"
        const height = "20px"
        const minWidth = "5px"
        return {
            width,
            backgroundColor : colorsMap.get(indicator),
            height,
            minWidth
        }
    }

    const Indicator = (props: { indicator : string }) => {
        const {max_value, value} = record && record.metadata.attributes.find((at: any) => at.trait_type === props.indicator)
        return <>
            <div style={{display: "flex"}}>
                <div style={setStyle(props.indicator, value, max_value)}></div>
                <span>&nbsp;{value}</span>
            </div>
        </>
    }
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TabbedShowLayout>
        <Tab label="general">
          <TextField source='id'/>
          <TextField source='tokenId'/>
          <ReferenceField source='ownerId' reference='users' label='Owner' link="show">
            <TextField source='nickname'/>
          </ReferenceField>
          <TextField source='ownerId' label="Owner ID"/>
            <DateField source='createdAt' showTime />
          <TextField source='metadata.image' label="Link"/>
                <ImageField  source="metadata.image" title="Joker Image" />
        </Tab>

        <Tab label="Indicators" path="indicators">

            <FunctionField
                label="Level"
                render={(record: any) => record.metadata.attributes.find((attr: any) => attr.trait_type === "level").value}
            />;
            <FunctionField
                label="Class"
                render={(record: any) => record.metadata.attributes.find((attr: any) => attr.trait_type === "class").value}
            />;

            <span>Craziness</span>
            <Indicator indicator={"craziness"}></Indicator>
            <span>Stamina</span>
            <Indicator indicator={"stamina"}></Indicator>
            <span>Elegance</span>
            <Indicator indicator={"elegance"}></Indicator>
            <span>Extra craziness</span>
            <Indicator indicator={"extra_craziness"}></Indicator>

            <FunctionField
                label="Games to monetize"
                render={(record: any) => record.gamesToPlay}
            />;

            <FunctionField
                label="Daily coins"
                render={(record: any) => record.dailyCoins}
            />;

            <FunctionField
                label="Attribute points"
                render={(record: any) => record.attributePoints}
            />;

          <DateField source='createdAt' showTime />
        </Tab>
      </TabbedShowLayout>
      </SimpleShowLayout>
    </Show>
  );
};
