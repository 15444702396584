import * as React from 'react';
import {
    BulkDeleteButton,
    Datagrid,
    List,
    ListProps,
    SearchInput,
    TextField,
    EditButton, DateField
} from 'react-admin';

const authorFilters = [
    <SearchInput source='search' alwaysOn/>,
];

export const ApplicationSettingsList = (props: ListProps) => {

    return (
        <List filters={authorFilters} {...props} >
            <Datagrid rowClick="edit" >
                <TextField source='id' sortable={false}/>
                <TextField source='name' label='Setting name'/>
                <TextField source='value' label='Setting value'/>
                <DateField source='createdAt' showTime />
                <DateField source='updatedAt' showTime />
                <EditButton />
            </Datagrid>
        </List>
    );

};
