import {
  DateField,
  FunctionField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  useShowController,
} from 'react-admin';
import { EarningTokenActions } from './_earning-token-actions';
import { NFTTokenActions } from './_nft-token-actions';
import * as React from "react";
import { makeStyles } from '@mui/styles';
import {Link } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles({
  won:{color : "green"},
  lost:{color : "red"},
  draw:{color :"grey"},
  total:{color :"blue"},
})

export const UserShow = (props: ShowProps) => {

  const { record } = useShowController(props);
  const classes = useStyles();

  const ReportsTable = () => {
    if (record?.reports && record?.reports.length) return <>
      <h4>Reports</h4>
      <TableContainer component={Paper}>
      <Table sx={{minWidth: 650}} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell> id</TableCell>
            <TableCell align="right">Reporter</TableCell>
            <TableCell align="right">Defendant</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Content</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {record?.reports && record.reports.map((report: any) => (
              <TableRow
                  key={report.id}
                  sx={{'&:last-child td, &:last-child th': {border: 0}}}
              >
                <TableCell component="th" scope="report">
                  <Link href={`#/reports/${report.id}/show`}>{report.id}</Link>
                </TableCell>
                <TableCell align="right">
                  <Link href={`#/users/${report.reporterId}/show`}>{report.reporterId}</Link>
                </TableCell>
                <TableCell align="right">
                  <Link href={`#/users/${report.defendantId}/show`}>{report.defendantId}</Link>
                </TableCell>
                <TableCell align="right">{report.type}</TableCell>
                <TableCell align="right">{report.content}</TableCell>
              </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
    else return <h4>No reports</h4>
  }
  return (
      <Show {...props}>
      <SimpleShowLayout>
        <TextField source='id' label={"User Id"}/>
        <TextField source='email'/>
        <FunctionField render={(record: any) => record.wallets[0].address} source='wallets'/>
        <FunctionField render={(record: any) => {
          if(record?.stats){
            const {Total, Won, Lost , Draw}: any = record.stats[0]
            return <>
              <pre className={classes.won}>Won: {Won}</pre>
              <pre className={classes.lost}>Lost: {Lost}</pre>
              <pre className={classes.draw}>Draw: {Draw}</pre>
              <pre className={classes.total}>Total: {Total}</pre>
            </>
          }
        }} source='stats'/>
        {record?.lastGameActivity && <DateField source='lastGameActivity'showTime/>}
        <DateField source='createdAt' showTime/>
        <DateField source='updatedAt' showTime/>
          <ReportsTable/>
      </SimpleShowLayout>
      {record && <EarningTokenActions user={record}/>}
      {record && <NFTTokenActions user={record} />}
    </Show>
  );
};
