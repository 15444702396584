import {
  AutocompleteInput,
  Button,
  Form,
  NumberInput,
  ReferenceInput,
  required,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import EngineeringIcon from '@mui/icons-material/Engineering';

export const JokerGenerationTaskCreate = function() {

  const navigate = useNavigate();

  // TODO: Load from some shared data
  const nftClasses = ['common', 'epic', 'rare', 'legendary'];

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const generateNftTokens = (formData: any) => {
    const query: any[] = [];
    nftClasses.forEach((klass) => {
      query.push({
        className: klass,
        amount: formData[klass] || 0,
      });
    });
    dataProvider.sendRequest('POST', 'joker-generation-tasks', {
      query: query,
      ownerId: !!formData.ownerId ? formData.ownerId : undefined,
    })
      .then((res: any) => {
        notify(`Generation request succeed`, { type: 'success' });
        navigate('/joker-generation-tasks');
      })
      .catch((err: any) => {
        notify(`Generation request failed ${err.message}`, { type: 'error' });
      });
  };

  function submitForm(formData: any) {
    generateNftTokens(formData);
  }

  return (
    <>
      <h3>NFT Generation</h3>
      <Grid container xl={6}>
        <Form onSubmit={submitForm}>
          <ReferenceInput label='Owner' source='ownerId' reference='users'>
            <AutocompleteInput optionText='email' optionValue={'id'}
                               filterToQuery={(searchText => ({ search: searchText }))}
            />
          </ReferenceInput>
          {
            nftClasses.map(klass => {
              return <NumberInput fullWidth min={0} max={100} source={klass} name={klass} key={klass}
                                  validate={required()}
              />;
            })
          }
          <Button type={'submit'} label={'Create'} size={'large'} fullWidth><EngineeringIcon /></Button>
        </Form>
      </Grid>
    </>
  );
};
