import {
    BulkDeleteButton,
    Datagrid,
    List,
    ListProps,
    SearchInput,
    TextField,
    DeleteWithConfirmButton,
    EditButton,
    DateField,
    ShowButton,
    FunctionField,
    TextInput
} from 'react-admin';
import { ButtonProps } from 'ra-ui-materialui/src/button/Button';
import * as React from "react";
import {Link} from "@mui/material";

const authorFilters = [
    <SearchInput source='search' alwaysOn />,
    <TextInput label="By users email" source="userFilter" />,
];

const MyBulkActionButtons = (props: ButtonProps) => {
    return (
        <BulkDeleteButton
            mutationMode={"pessimistic"}
            confirmTitle={'Are you sure?'}
            confirmContent={'You are going to permanently delete these users'}
            {...props}
        />
    );
};


export const NotificationList = (props: ListProps) => {

    return (
        <List filters={authorFilters} {...props} bulkActionButtons={<MyBulkActionButtons />}>
            <Datagrid bulkActionButtons={<MyBulkActionButtons />}>
                <TextField source='id' sortable={false}/>
                <TextField source='title' />
                <TextField source='content'/>
                <FunctionField
                    label="Receivers"
                    render={(record: any) => {
                        return <>
                            {record.receivers.map((item: any) => <pre><Link href={`#/users/${item}/show`}>{item}</Link></pre>)}
                        </>
                    }}
                />;
                <FunctionField
                    label="Readers"
                    render={(record: any) => {
                        return <>
                            {record.readers.map((item: any) => <pre><Link href={`#/users/${item}/show`}>{item}</Link></pre>)}
                        </>
                    }}
                />;
                <DateField source='createdAt' showTime />
                <DateField source='updatedAt' showTime />
                <DeleteWithConfirmButton />
            </Datagrid>
        </List>
    );
};
