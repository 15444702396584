import { Admin, CustomRoutes, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
import { authProvider } from './@services/auth.provider';
import { DataProvider } from './@services/data.provider';
import { CONFIG } from './@configurations/config';
import { UserCreate, UserEdit, UserList, UserShow } from './user';
import { ReportList, ReportShow } from './report';
import { TipsCreate, TipsEdit, TipsList, TipsShow } from './tips';
import { SupportRequestList, SupportRequestShow } from './support-request';
import {EffectCreate, EffectEdit, EffectList, EffectShow} from './effect';
import { JokersList, JokersShow } from './jokers';
import { AwsLogCreate, AwsLogList, AwsLogShow } from './aws-logs';
import { MyLayout } from './my-layout';
import NotificationSender from './notification-sender/notification-sender';
import { ApplicationSettingsEdit, ApplicationSettingsList} from "./application-settings";
import {
  JokerGenerationTaskCreate,
  JokerGenerationTaskList,
  JokerGenerationTaskShow,
} from './joker-generation-tasks';
import {NotificationList} from "./notifications";



const dataProvider = new DataProvider(`${CONFIG.API_HOST}/admin`);
const App = () =>
  <Admin dataProvider={dataProvider}
         authProvider={authProvider}
         layout={MyLayout}>
    <Resource name='users' list={UserList} show={UserShow} create={UserCreate} edit={UserEdit} />
    <Resource name='reports' list={ReportList} show={ReportShow} />
    <Resource name='tips' list={TipsList}
              show={TipsShow}
              create={TipsCreate}
              edit={TipsEdit}
    />
    <Resource name='jokers' list={JokersList} show={JokersShow} />
    <Resource name='joker-generation-tasks'
              list={JokerGenerationTaskList}
              show={JokerGenerationTaskShow}
              create={JokerGenerationTaskCreate}
    />
    <Resource name='support-requests' list={SupportRequestList} show={SupportRequestShow} />
    <Resource name='effects' list={EffectList} create={EffectCreate} edit={EffectEdit} show={EffectShow} />
    <Resource name='aws-logs' list={AwsLogList} create={AwsLogCreate} show={AwsLogShow} />
      <Resource name='application-settings'
                list={ApplicationSettingsList}
                edit={ApplicationSettingsEdit}
              />
      <Resource name='notifications' list={NotificationList} />
      <CustomRoutes>
      <Route path='/notification-sender' element={<NotificationSender />} />
    </CustomRoutes>
  </Admin>;

export default App;
