import CardContent from '@mui/material/CardContent';
import { Table, TableCell, TableRow } from '@mui/material';
import { Button, useDataProvider, useNotify } from 'react-admin';
import { AttachMoney, MoneyOff } from '@mui/icons-material';
import { useEffect, useState } from 'react';

export const EarningTokenActions = (props: any) => {
  const { user } = props;

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [balance, setBalance] = useState(0);

  useEffect(() => {
    getBalance();
  }, []);

  const getBalance = () => {
    dataProvider.sendRequest('GET', `earning-token?userId=${user.id}`)
      .then((res: any) => {
        setBalance(res.data.result.balance);
      })
      .catch((err: any) => {
        notify(`Balance load error: ${err.message}`, { type: 'error' });
      });
  };

  const mintEarningToken = () => {
    dataProvider.sendRequest('POST', 'earning-token', {
      userId: user.id,
      amount: '10',
    }).then(() => {
      setBalance(+balance + 10);
    })
      .catch((err: any) => {
        notify(`Minting error: ${err.message}`, { type: 'error' });
      });
  };

  const burnEarningToken = () => {
    dataProvider.sendRequest('DELETE', 'earning-token', {
      userId: user.id,
      amount: '5',
    }).then(() => {
      setBalance(balance - 5);
    })
      .catch((err: any) => {
        notify(`Burning error: ${err.message}`, { type: 'error' });
      });
  };

  return (
    <CardContent>
      <h4>Earning Token Actions</h4>
      <Table>
        <TableRow>
          <TableCell>
            Balance: {balance}
          </TableCell>
          <TableCell>
            <Button label={'Mint 10 Tokens'} onClick={mintEarningToken}><AttachMoney /></Button>
          </TableCell>
          <TableCell>
            <Button label={'Burn 5 Tokens'} onClick={burnEarningToken}><MoneyOff /></Button>
          </TableCell>
        </TableRow>
      </Table>
    </CardContent>
  );
};
