import { Edit, EditProps,  required, SimpleForm, TextInput } from 'react-admin';

export const TipsEdit = (props: EditProps) => {

    return (
        <Edit {...props} mutationMode={'pessimistic'}>
            <SimpleForm warnWhenUnsavedChanges>
                <TextInput disabled source='id' />
                <TextInput source='text' validate={[required()]} />
            </SimpleForm>
        </Edit>
    );
};
