import { Create, required, SimpleForm, TextInput , FileInput , FileField} from 'react-admin';
import { CreateProps } from 'ra-ui-materialui/dist/cjs/types';

export const AwsLogCreate = (props: CreateProps) => {
    return (
        <Create {...props}>
            <SimpleForm warnWhenUnsavedChanges>
                <TextInput source='gameId' validate={[required()]} />
                <TextInput source='userId' validate={[required()]} />
                <FileInput source="file"
                           accept='text/plain'
                           label="Related file"
                           multiple = { false }
                           labelSingle = "File accepted"
                           placeholder = {<p>📁Drop file here...</p>}
                           validate={[required()]}>
                    <FileField source="src" title="title" />
                </FileInput>
            </SimpleForm>
        </Create>
    );
};



