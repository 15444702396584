import CardContent from '@mui/material/CardContent';
import { Table, TableCell, TableRow } from '@mui/material';
import { Button, useDataProvider, useNotify } from 'react-admin';
import { AddReaction, EmojiEmotions } from '@mui/icons-material';
import { useEffect, useState } from 'react';

export const NFTTokenActions = (props: any) => {

  const { user } = props;

  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [balance] = useState(0);


  useEffect(() => {
    getBalance();
  }, []);

  const getBalance = () => {

  };

  const mintNftToken = () => {
    dataProvider.sendRequest('POST', 'jokers', {
      userId: user.id
    })
      .then((res: any) => {
        notify(`Minting success`, { type: 'success' });
      })
      .catch((err: any) => {
        notify(`Minting error: ${err.message}`, { type: 'error' });
      });
  };

  const mintOrdinaryJoker = () => {
    dataProvider.sendRequest('POST', 'jokers/ordinary', {
      user: user
    })
        .then((res: any) => {
          notify(`Minting success`, { type: 'success' });
        })
        .catch((err: any) => {
          notify(`Minting error: ${err.message}`, { type: 'error' });
        });
  }
  return (
    <CardContent>
      <h4>NFT Token Actions</h4>
      <Table>
        <TableRow>
          <TableCell>
            Balance: {balance}
          </TableCell>
          <TableCell>
            <Button label={'Give me a token'} onClick={mintNftToken}><AddReaction /></Button>
          </TableCell>
          <TableCell>
            <Button label={'Release ordinary joker'} onClick={mintOrdinaryJoker}><EmojiEmotions/>
          </Button>
          </TableCell>
        </TableRow>
      </Table>
    </CardContent>
  );
};

