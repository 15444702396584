import {
  DateField,
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  useShowController,
} from 'react-admin';


export const AwsLogShow = (props: ShowProps) => {
  const { record } = useShowController(props);
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source='id' />
        <ReferenceField source='userId' reference='users' label='Creator' link="show">
          <TextField source='email' />
        </ReferenceField>
        <DateField source='createdAt' showTime />
        <DateField source='updatedAt' showTime />
        {record && <p>Payload
          <pre>{JSON.stringify(record.payload, null, 2)}</pre>
        </p>}
      </SimpleShowLayout>
    </Show>
  );
};
