import {
  AutocompleteInput,
  Button,
  Form,
  ReferenceInput,
  required,
  TextInput,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { Grid } from '@mui/material';
import EngineeringIcon from '@mui/icons-material/Engineering';

const NotificationSender = function() {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const createNotification = (formData: any) => {
    const receiverIds = [];
    if (formData.receiverId) {
      receiverIds.push(formData.receiverId);
    }
    dataProvider.sendRequest('POST', 'notifications', {
      receiverIds: receiverIds,
      title: formData.title,
      content: formData.content,
    })
      .then((res: any) => {
        notify(`Push Successfully sent`, { type: 'success' });
      })
      .catch((err: any) => {
        notify(`Push Sending failed ${err.message}`, { type: 'error' });
      });
  };

  function submitForm(formData: any) {
    createNotification(formData);
  }

  return (
    <>
      <h3>Create Notification</h3>
      <Grid container xl={6}>
        <Form onSubmit={submitForm}>
          <ReferenceInput label='Receiver' source='receiverId' reference='users' >
            <AutocompleteInput filterToQuery={(searchText => ({ search: searchText }))}
                               optionText='email' optionValue={'id'} />
          </ReferenceInput>
          <TextInput source={'title'} validate={required()} fullWidth />
          <TextInput source={'content'} multiline rows={5} validate={required()} fullWidth />
          <Button type={'submit'} label={'Create Notification'} size={'large'} fullWidth>
            <EngineeringIcon />
          </Button>
        </Form>
      </Grid>
    </>
  );
};

export default NotificationSender;
