import {
    DateField,
    Show,
    ShowProps,
    SimpleShowLayout,
    TextField,
    useRecordContext,
    Button, useDataProvider,
} from 'react-admin';
import {useEffect, useState} from "react";
import Photo from "@mui/icons-material/Photo";
import {CONFIG} from "../@configurations/config";

function _arrayBufferToBase64(buffer: ArrayBuffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

const ReportScreen = () => {

    const record = useRecordContext();
    const [imageUrl, setImageUrl] = useState<string | null>('')
    const [loading, setLoading] = useState<boolean>(false)
    const dataProvider = useDataProvider();
    useEffect(() => {
        (async (record) => {
            setLoading(true)
            const response = await dataProvider.getResponseTypeRequest(`${CONFIG.API_HOST}/admin/reports/${record.id}/screen`, 'arraybuffer')
            if (response.data.byteLength === 0) {
                setLoading(false)
                return setImageUrl(null)
            }
            const binaryData = response.data
            const base64 = _arrayBufferToBase64(binaryData);
            const dataUrl = `data:image/png;base64,${base64}`;
            setImageUrl(dataUrl)
            setLoading(false)
        })(record)
    }, [record, setImageUrl])
    if (loading) return <span>Loading,please wait...</span>;
    if (!imageUrl) return <span>No report screen</span>;
    return <img style={{objectFit: 'contain'}} src={imageUrl}/>
};

export const ReportShow = (props: ShowProps) => {
    const [download, setDownload] = useState(false)
    return (
        <Show {...props} >
            <SimpleShowLayout>
                <TextField source='id'/>
                <TextField source='content'/>
                <TextField source='type'/>
                <TextField source='reporterId'/>
                <TextField source='defendantId'/>
                <DateField source='createdAt'/>
                <DateField source='updatedAt'/>
                {!download ? <Button onClick={(_) => {
                    setDownload(true)
                }}
                                     color={"primary"}
                                     size={"large"}
                                     label={'Show report screen'}>
                    <Photo/>
                </Button> : <ReportScreen/>}
            </SimpleShowLayout>
        </Show>
    );
};
