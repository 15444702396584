import {
  BulkDeleteButton,
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  FunctionField,
  List,
  ListProps, ReferenceField,
  SearchInput,
  ShowButton,
  TextField,
} from 'react-admin';
import { ButtonProps } from 'ra-ui-materialui/src/button/Button';

const authorFilters = [
  <SearchInput source='search' alwaysOn />,
];

const MyBulkActionButtons = (props: ButtonProps) => {
  return (
    <BulkDeleteButton
      confirmTitle={'Are you sure?'}
      confirmContent={'You are going to permanently delete these users'}
      {...props}
    />
  );
};

export const JokerGenerationTaskList = (props: ListProps) => {

  return (
    <List filters={authorFilters} {...props}>
      <Datagrid bulkActionButtons={<MyBulkActionButtons />}>
        <TextField source='id' />
        <ReferenceField source='ownerId' reference='users' link="show">
          <TextField source='email' />
        </ReferenceField>
        <TextField source='status'/>
        <FunctionField
          label="Schema"
          render={(record: any) => {
            return (
              record && <p>Schema
                <pre>{JSON.stringify(record.schema, null, 2)}</pre>
              </p>
            )
          }}
        />

        <ShowButton />
        <DeleteWithConfirmButton />
      </Datagrid>
    </List>
  );
};
