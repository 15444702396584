import {
    DateField,
    ImageField,
    Show,
    ShowProps,
    SimpleShowLayout,
    TextField,
    useShowController
} from 'react-admin';
import {CONFIG} from '../@configurations/config'

export const EffectShow = (props: ShowProps) => {

    const {record} = useShowController(props);
    const Icon = () => {
        const url = `${CONFIG.API_HOST}/${record.icon}`
        return <img style={{objectFit: 'contain'}} src={url} height={100} width={100}/>
    }
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source='id'/>
                <TextField source='name'/>
                <TextField source='type'/>
                <TextField source='icon'/>
                <ImageField source="metadata.image" title="Joker Image"/>
                <Icon/>
                <DateField source='createdAt'/>
                <DateField source='updatedAt'/>
            </SimpleShowLayout>
        </Show>
    );
};
