import { CONFIG } from '../@configurations/config';

const authBaseUrl = `${CONFIG.API_HOST}/admin`;
const HTTP_SUCCESS = 200;
const HTTP_CREATED = 201;

export async function createSession(username: string, password: string) {
  const response = await fetch(`${authBaseUrl}/login`, {
    method: 'POST',
    body: JSON.stringify({
      email: username,
      password,
    }),
    headers: { 'Content-Type': 'application/json' },
  });
  const json = await response.json();
  if (response.status !== HTTP_CREATED) {
    throw new Error(json.message);
  }
  return json.token.accessToken;
}

export async function validateSession(token: string) {
  if (!token) throw new Error('Not authorized');

  const response = await fetch(`${authBaseUrl}/validate`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const json = await response.json();
  if (response.status !== HTTP_SUCCESS) {
    throw new Error(json.message);
  }
}
