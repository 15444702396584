import {
  DateField,
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  useShowController,
} from 'react-admin';

export const JokerGenerationTaskShow = (props: ShowProps) => {

  const { record } = useShowController(props);

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source='id' />
        <ReferenceField source='ownerId' reference='users' link="show">
          <TextField source='email' />
        </ReferenceField>
        <TextField source='status'/>

        {record && <p>Schema
          <pre>{JSON.stringify(record.schema, null, 2)}</pre>
        </p>}

        {record && <p>Payload
          <pre>{JSON.stringify(record.payload, null, 2)}</pre>
        </p>}

        <DateField source='createdAt' />
        <DateField source='updatedAt' />
      </SimpleShowLayout>
    </Show>
  );
};
