import {
    Datagrid,
    DateField,
    FunctionField,
    List,
    ListProps,
    ReferenceField, SearchInput,
    ShowButton,
    TextField,
} from 'react-admin';
import * as React from "react";

const authorFilters = [
    <SearchInput source='search' alwaysOn />,
];

export const JokersList = (props: ListProps) => {

  return (
    <List {...props} filters={authorFilters}>
        <Datagrid>
            <TextField source='id' sortable={false}/>
            <TextField source='tokenId' sortable={false}/>
            <ReferenceField source='ownerId' reference='users' label='Owner' link="show">
                <TextField source='nickname'/>
            </ReferenceField>
            <TextField sortable={false} source='ownerId' label="Owner ID"/>
            <TextField sortable={false} source='metadata.image' label="Link"/>
            <FunctionField
                label="Class"
                render={(record: any) => record.metadata.attributes.find((attr: any) => attr.trait_type === "class").value}
            />;

            <FunctionField
                label="Level"
                render={(record: any) => record.metadata.attributes.find((attr: any) => attr.trait_type === "level").value}
            />;
            <FunctionField
                label="Craziness"
                render={(record: any) => {
                    const {
                        value: currentLevel,
                    } = record.metadata.attributes.find((attr: any) => attr.trait_type === "craziness")
                    return <pre>{currentLevel}</pre>
                }}
            />;
            <FunctionField
                label="Elegance"
                render={(record: any) => {
                    const {
                        value: currentLevel,
                    } = record.metadata.attributes.find((attr: any) => attr.trait_type === "elegance")
                    return <pre>{currentLevel}</pre>

                }}
            />;
            <FunctionField
                label="Stamina"
                render={(record: any) => {
                    const {
                        value: currentLevel,
                        max_value: maxLevel
                    } = record.metadata.attributes.find((attr: any) => attr.trait_type === "stamina")
                    return <pre>{currentLevel}</pre>
                }}
            />;
            <FunctionField
                label={"Extra craziness"}
                render={(record: any) => {
                    const {
                        value: currentLevel,
                        max_value: maxLevel
                    } = record.metadata.attributes.find((attr: any) => attr.trait_type === "extra_craziness")
                    return <pre>{currentLevel}</pre>
                }}
            />;
            <DateField source='createdAt' showTime />
            <DateField source='updatedAt' showTime />
            <ShowButton/>
        </Datagrid>
    </List>
  );
};
