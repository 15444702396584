import {
    BulkDeleteButton,
    Datagrid, DateField,
    DeleteWithConfirmButton,
    List,
    ListProps, ReferenceField,
    SearchInput,
    ShowButton,
    TextField,
} from 'react-admin';
import { ButtonProps } from 'ra-ui-materialui/src/button/Button';
import * as React from "react";

const authorFilters = [
    <SearchInput source='search' alwaysOn />,
];

const MyBulkActionButtons = (props: ButtonProps) => {
    return (
        <BulkDeleteButton
            mutationMode={"pessimistic"}
            confirmTitle={'Are you sure?'}
            confirmContent={'You are going to permanently delete these users'}
            {...props}
        />
    );
};

export const AwsLogList = (props: ListProps) => {

    return (
        <List filters={authorFilters} {...props} bulkActionButtons={<MyBulkActionButtons />}>
            <Datagrid bulkActionButtons={<MyBulkActionButtons />}>
                <TextField source='id' sortable = {false}/>
                <ReferenceField source='userId' reference='users' label='Creator' link="show">
                    <TextField source='email' />
                </ReferenceField>
                <TextField source='roomSid' sortable = {false} />
                <DateField source='createdAt' showTime />
                <DateField source='updatedAt' showTime />
                <ShowButton />
                <DeleteWithConfirmButton />
            </Datagrid>
        </List>
    );
};
