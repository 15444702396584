export class AuthTokenProvider {

  _key = 'AB_TOKEN';

  get token(): string {
    return localStorage.getItem(this._key) || '';
  }

  set token(token: string) {
    localStorage.setItem(this._key, token);
  }

  clear() {
    localStorage.removeItem(this._key);
  }
}
