import { Edit, EditProps, email, required, SimpleForm, TextInput } from 'react-admin';

export const UserEdit = (props: EditProps) => {

  return (
    <Edit {...props} mutationMode={'pessimistic'}>
      <SimpleForm warnWhenUnsavedChanges>
        <TextInput disabled source='id' />
        <TextInput source='email' validate={[required(), email()]} />
      </SimpleForm>
    </Edit>
  );
};
