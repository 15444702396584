import {
    BulkDeleteButton,
    Datagrid,
    List,
    ListProps,
    SearchInput,
    TextField,
    DeleteWithConfirmButton,
    EditButton, DateField, ShowButton
} from 'react-admin';
import { ButtonProps } from 'ra-ui-materialui/src/button/Button';
import * as React from "react";

const authorFilters = [
    <SearchInput source='search' alwaysOn />,
];

const MyBulkActionButtons = (props: ButtonProps) => {
    return (
        <BulkDeleteButton
            mutationMode={"pessimistic"}
            confirmTitle={'Are you sure?'}
            confirmContent={'You are going to permanently delete these users'}
            {...props}
        />
    );
};


export const EffectList = (props: ListProps) => {

    return (
        <List filters={authorFilters} {...props} bulkActionButtons={<MyBulkActionButtons />}>
            <Datagrid bulkActionButtons={<MyBulkActionButtons />}>
                <TextField source='id' sortable={false}/>
                <TextField source='name' />
                <TextField source='type' />
                <TextField source='link' sortable={false}/>
                <TextField source='icon' sortable={false}/>
                <DateField source='createdAt' showTime />
                <DateField source='updatedAt' showTime />
                <ShowButton />
                <EditButton />
                <DeleteWithConfirmButton />
            </Datagrid>
        </List>
    );
};
