import { Edit, EditProps, FileField, FileInput, SimpleForm, TextInput } from 'react-admin';

export const EffectEdit = (props: EditProps) => {

  return (
    <Edit {...props} mutationMode={'pessimistic'}>
      <SimpleForm warnWhenUnsavedChanges>
        <TextInput disabled source='id' />
        <TextInput source='name' />
        <FileInput source='effect'
                   label='Effect file'
                   multiple={false}
                   labelSingle='Effect accepted'
                   placeholder={<p>✨Drop effect file here...</p>}>
          <FileField source='src' title='title' />
        </FileInput>
        <FileInput source='icon'
                   label='Icon file'
                   accept='image/*'
                   multiple={false}
                   labelSingle='Icon accepted'
                   placeholder={<p>📁Drop icon file here...</p>}>
          <FileField source='src' title='title' />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};
