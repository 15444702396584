import { createElement } from 'react';
import { DashboardMenuItem, Menu, MenuItemLink, useResourceDefinitions, useSidebarState } from 'react-admin';
import DefaultIcon from '@mui/icons-material/ViewList';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';

export const MyMenu = (props: any) => {
  const resources = useResourceDefinitions()
  const [open] = useSidebarState();
  return (
    <Menu {...props}>
      <DashboardMenuItem />
      {Object.keys(resources).map(name => (
        <MenuItemLink
          key={name}
          to={`/${name}`}
          primaryText={
            (resources[name].options && resources[name].options.label) ||
            name
          }
          leftIcon={
            resources[name].icon ? createElement(resources[name].icon) : <DefaultIcon />
          }
          onClick={props.onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
      <MenuItemLink to="/notification-sender" primaryText="Create Notification" leftIcon={<MarkEmailUnreadIcon />}/>
    </Menu>
  );
};
