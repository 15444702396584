import { AuthTokenProvider } from './auth-token.provider';
import { createSession, validateSession } from './session.service';
import { UserIdentity } from 'react-admin';

const tokenProvider = new AuthTokenProvider();

export const authProvider = {
    // authentication
    login: async (params: any) => {
        const {username, password} = params;
        tokenProvider.token = await createSession(username, password);
    },
    checkError: (error: Error) => Promise.resolve(),
    checkAuth: async (params: any) => {
        await validateSession(tokenProvider.token);
    },
    logout: async () => {
        tokenProvider.clear();
    },
    getIdentity: () => {
        const fake: UserIdentity = {
            id: Date.now(),
        }
        return Promise.resolve(fake);
    },
    // authorization
    getPermissions: (params: any) => Promise.resolve(),
};
