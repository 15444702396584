import {
  DataProvider as IDataProvider,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams, HttpError,
} from 'react-admin';
import axios from 'axios';
import { CreateParams, DeleteManyParams, DeleteParams, UpdateManyParams, UpdateParams } from 'ra-core/src/types';
import { AuthTokenProvider } from './auth-token.provider';
import { omitBy } from 'lodash';

const tokenProvider = new AuthTokenProvider();

const countDiff = (o1: any, o2: any) =>
  omitBy(o1, (v, k) => o2[k] === v);

export class DataProvider implements IDataProvider {

  constructor(private url: string) {
  }

  private get request() {
    const token = tokenProvider.token;
    return axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  sendRequest(method: string, resource: string, data?: any) {
    return this.request.request({
      method,
      url: `${this.url}/${resource}`,
      data,
    });
  }

  getResponseTypeRequest(url:string,responseType : string = 'application/json' ){
    const authToken = tokenProvider.token;
    return axios.get(url, {
      //@ts-ignore
      responseType,
      headers: {
        Authorization: 'Bearer ' + authToken
      },
    })
  }

  getOne(resource: string, params: GetOneParams) {
    return this.request.get(`${this.url}/${resource}/${params.id}`, { params })
      .then(res => res.data)
      .catch(err => {
        const data = err.response.data;
        throw new HttpError(data.message, data.statusCode);
      });
  }

  getList(resource: string, params: GetListParams) {
    return this.request.get(`${this.url}/${resource}`, { params })
      .then(res => res.data)
      .catch(err => {
        const data = err.response.data;
        throw new HttpError(data.message, data.statusCode);
      });
  }

  getMany(resource: string, params: GetManyParams) {
    return this.request.get(`${this.url}/${resource}`, { params })
      .then(res => res.data)
      .catch(err => {
        const data = err.response.data;
        throw new HttpError(data.message, data.statusCode);
      });
  }

  getManyReference(resource: string, params: GetManyReferenceParams) {
    return this.request.get(`${this.url}/${resource}`, { params })
      .then(res => res.data)
      .catch(err => {
        const data = err.response.data;
        throw new HttpError(data.message, data.statusCode);
      });
  }

  update(resource: string, params: UpdateParams) {
    let data = countDiff(params.data, params.previousData);
    const file = Object.values(data).find((v: any) => v.rawFile instanceof File);
    if (file) {
      data = convertToMultipart(data);
    }
    return this.request.patch(`${this.url}/${resource}/${params.id}`, data)
      .then(res => res.data)
      .catch(err => {
        const data = err.response.data;
        throw new HttpError(data.message, data.statusCode);
      });
  }

  updateMany(resource: string, params: UpdateManyParams) {
    return this.request.patch(`${this.url}/${resource}/${params.ids}`, { data: params.data })
      .then(res => res.data)
      .catch(err => {
        const data = err.response.data;
        throw new HttpError(data.message, data.statusCode);
      });
  }

  create(resource: string, params: CreateParams) {
    let data = params.data;
    const file = Object.values(data).find((v: any) => v.rawFile instanceof File);
    if (file) {
      data = convertToMultipart(data);
    }
    return this.request.post(`${this.url}/${resource}`, data)
      .then(res => res.data)
      .catch(err => {
        const data = err.response.data;
        throw new HttpError(data.message, data.statusCode);
      });
  };

  delete(resource: string, params: DeleteParams) {
    return this.request.delete(`${this.url}/${resource}/${params.id}`, {})
      .then(res => res.data)
      .catch(err => {
        const data = err.response.data;
        throw new HttpError(data.message, data.statusCode);
      });
  }

  deleteMany(resource: string, params: DeleteManyParams) {
    return this.request.delete(`${this.url}/${resource}/${params.ids}`, {})
      .then(res => res.data)
      .catch(err => {
        const data = err.response.data;
        throw new HttpError(data.message, data.statusCode);
      });
  }
}

const convertToMultipart = (data: Record<string, any>) => {
  const formData = new FormData();
  for (let key in data) {
    const value = data[key];
    if (value.rawFile && value.rawFile instanceof File) {
      formData.append(key, value.rawFile, value.title);
    } else {
      formData.append(key, value);
    }
  }
  return formData;
};
