import {
    BulkDeleteButton,
    Datagrid, DateField,
    DeleteWithConfirmButton,
    EditButton,
    List,
    ListProps,
    SearchInput,
    ShowButton,
    TextField,
} from 'react-admin';
import { ButtonProps } from 'ra-ui-materialui/src/button/Button';
import * as React from "react";

const authorFilters = [
    <SearchInput source='search' alwaysOn />,
];


const MyBulkActionButtons = (props: ButtonProps) => {


    return (
        <BulkDeleteButton
            mutationMode={"pessimistic"}
            confirmTitle={'Are you sure?'}
            confirmContent={'You are going to permanently delete these users'}
            {...props}
        />
    );
};


export const TipsList = (props: ListProps) => {

    return (
        <List filters={authorFilters} {...props} bulkActionButtons={<MyBulkActionButtons />}>
            <Datagrid >
                <TextField source='id' sortable={false}/>
                <TextField source='text' />
                <DateField source='createdAt' showTime />
                <DateField source='updatedAt' showTime />
                <ShowButton />
                <EditButton />
                <DeleteWithConfirmButton />
            </Datagrid>
        </List>
    );
};
