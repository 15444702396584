import {Edit, EditProps , SimpleForm, TextInput} from 'react-admin';

export const ApplicationSettingsEdit = (props: EditProps) => {

    return (
        <Edit {...props} mutationMode={'pessimistic'}>
            <SimpleForm warnWhenUnsavedChanges>
                <TextInput disabled source='name' label="Setting name"/>
                <TextInput source='value' label='Setting value'/>
            </SimpleForm>
        </Edit>
    );
};
