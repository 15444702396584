import { DateField, Show, ShowProps, SimpleShowLayout, TextField } from 'react-admin';


export const TipsShow = (props: ShowProps) => {

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source='id' />
                <TextField source='text' />
                <DateField source='createdAt' />
                <DateField source='updatedAt' />
            </SimpleShowLayout>
        </Show>
    );
};
