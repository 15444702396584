import {DateField, ReferenceField, Show, ShowProps, SimpleShowLayout, TextField} from 'react-admin';


export const SupportRequestShow = (props: ShowProps) => {

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source='id' />
                <TextField source='message' />
                <ReferenceField source='userId' reference='users' label='Sender' link="show">
                    <TextField source='email' />
                </ReferenceField>
                <DateField source='createdAt' />
                <DateField source='updatedAt' />
            </SimpleShowLayout>
        </Show>
    );
};
